import { getClient } from '@klokgroep/sanity/src/sanity.server';
import { groq } from 'next-sanity';
import { SiteIdType } from '@klokgroep/sanity/src/sites';
import { createSeoQuery, SeoQuery } from '@klokgroep/sanity/src/queries/helpers/seoQuery';
import { createParentsQuery } from '@klokgroep/sanity/src/queries/helpers/createHrefQueryWithParents';
import {
  corporatePageHeroQuery,
  getCorporateContentModulesQueries,
  CorporatePageHeroQuery,
  MappedCorporateContentModules,
} from '../corporateQueries';
import { contactPersonQuery } from '@klokgroep/sanity/src/queries/helpers/contactPersonQuery';

export const createCorporatePageQuery = (siteId: SiteIdType, preview?: boolean, pageType?: 'job') => {
  let typeFilter = `_type == 'corporate-page' && lower(slug.current) == lower($slug) ${
    siteId ? `&& siteId == $siteId` : ``
  }`;

  if (pageType === 'job') {
    typeFilter = `${typeFilter} && parent._ref match 'corporate-jobsOverviewPage*'`;
  }

  return groq`*[${typeFilter}] | order(_updatedAt desc)[0] {
  _id,
  _type,
  slug,
  ${createSeoQuery(siteId)},
  ${corporatePageHeroQuery},
  corporateContentModules[] { ...select( ${getCorporateContentModulesQueries(siteId, preview)} ), },
  ${contactPersonQuery},
  siteId,
  ${createParentsQuery()}
}`;
};

export type CorporatePageProperties = Sanity.Schema.CorporatePage & CorporatePageHeroQuery;

export interface GetCorporatePageQueryResult
  extends Omit<CorporatePageProperties, 'seo' | 'corporateContentModules' | 'parent'> {
  seo: SeoQuery;
  corporateContentModules: MappedCorporateContentModules;
  parent?: {
    _type?: Sanity.Schema.Document['_type'];
    slug?: { _type?: 'slug'; current?: string };
    title?: string;
  };
}

export const getCorporatePage = async (preview = false, slugs: string[], siteId: SiteIdType) => {
  const endingSlug = slugs.at(-1);

  return getClient(preview).fetch<GetCorporatePageQueryResult>(createCorporatePageQuery(siteId), {
    siteId,
    slug: endingSlug,
  });
};

interface CorporatePageWithParentReference {
  _id: string;
  siteId: SiteIdType;
  slug: {
    current: string;
  };
  parent?: {
    _ref: string;
  };
}

export const getCorporatePages = async (preview = false, siteId?: SiteIdType, filterNoIndex?: boolean) => {
  const pages = await getClient(preview).fetch<CorporatePageWithParentReference[]>(
    groq`*[_type == 'corporate-page' && defined(slug.current) && siteId == $siteId${
      filterNoIndex ? ` && seo.noIndex != true && parent->seo.noIndex != true` : ''
    }] {
      _id,
      siteId,
      slug,
      parent{ _ref },
    }`,
    { siteId: siteId ?? '' }
  );

  const pagesWithoutParent = pages.filter((page) => page.parent === null);
  const pagesWithParent = pages.filter((page) => page.parent !== null);

  const slugPossibilities: CorporatePageWithParentReference[][] = [];

  for (const page of pagesWithoutParent) {
    slugPossibilities.push([page]);
  }

  while (pagesWithParent.length > 0) {
    const previousLength = pagesWithParent.length;
    for (let index = 0; index < pagesWithParent.length; index++) {
      const pageWithParent = pagesWithParent[index];
      const parentIndex = slugPossibilities.findIndex(
        (pages) => pages[pages?.length - 1]._id === pageWithParent.parent?._ref
      );

      if (parentIndex === -1) {
        continue;
      }
      slugPossibilities.push([...slugPossibilities[parentIndex], pageWithParent]);
      pagesWithParent.splice(index, 1);
    }
    if (pagesWithParent.length === previousLength) {
      break;
    }
  }

  return slugPossibilities.map((pages) => ({
    slugs: pages.map((page) => page.slug.current),
    siteId: pages[0].siteId,
  }));
};
